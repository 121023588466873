<template>
  <div>
    <MyLink
      v-if="!showPopup"
      v-bind="linkData"
      @click="
        showPopup = true;
        console.log('Newsletter abonnieren');
      "
    />
    <div v-if="showPopup" class="d-block">
      <MailchimpSubscribe
        class="w-100"
        horizontal
        no-cta
        @subscribed="subscribed = true"
      />

      <v-btn v-if="!subscribed" icon @click="showPopup = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  button: {
    type: Boolean,
    default: true,
  },
  label: {
    type: String,
    default: null,
  },
});
const { linkData } = await useNewsletterSubscribeButton(props);
const showPopup = ref(false);
const subscribed = ref(false);
</script>
